html,
body {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden; }
  @media only screen and (max-width: 768px) {
    html,
    body {
      background: unset; } }

body {
  padding-top: 200px;
  background-color: #F6F6F6; }
  @media only screen and (max-width: 1200px) {
    body {
      padding-top: 60px; } }
  @media only screen and (min-width: 992px) and (max-width: 1119px) {
    body {
      padding-top: 165px; } }

/*#root {
    width: 100%;
    width: 100vw;
    overflow-x: hidden;
    >div {
        >div {
            width: 100%;
            float: left;
            overflow-x: hidden;
        }
    }
}*/
.text-bold {
  font-weight: 600; }

button {
  cursor: pointer; }

.form-container {
  padding: 0; }

.content-wrap {
  padding: 0; }

.breadcrumb {
  background-color: transparent;
  padding: 0;
  padding-bottom: 38px;
  margin: 0;
  justify-content: flex-end; }
  .breadcrumb > li {
    font-size: 18px; }
    .breadcrumb > li::after {
      content: "//";
      padding: 0 6px; }
    .breadcrumb > li a {
      color: #333333; }
    .breadcrumb > li:last-child::after {
      content: ''; }
    .breadcrumb > li:last-child a {
      color: #ED3237;
      font-weight: 600; }
  @media only screen and (max-width: 768px) {
    .breadcrumb {
      display: none; } }

.section {
  padding: 20px 0;
  width: 100%; }
  @media only screen and (max-width: 768px) {
    .section {
      padding: 10px 0; } }
  .section h2 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 22px;
    color: #ED3237; }
    .section h2 > span {
      font-weight: 400;
      color: #333333; }
    @media only screen and (max-width: 768px) {
      .section h2 {
        font-size: 20px; } }
  .section .header-top {
    display: none; }
  .section .home-wrap .header {
    display: none; }
  .section .home-wrap .contact-phone {
    display: none; }

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: 100vh;
  background-color: rgba(35, 38, 40, 0.9);
  z-index: 9999; }
  .lightbox .mdi-close {
    position: fixed;
    top: 16px;
    right: 16px;
    font-size: 48px;
    color: #FFFFFF;
    cursor: pointer;
    z-index: 99999; }
  .lightbox .lightbox-item {
    padding: 100px 200px;
    width: 100%;
    height: 100vh; }
    .lightbox .lightbox-item img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center; }
    .lightbox .lightbox-item .title {
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      padding-top: 16px; }
  .lightbox .carousel-control-prev-icon {
    width: 48px;
    height: 48px;
    cursor: pointer; }
  .lightbox .carousel-control-next-icon {
    width: 48px;
    height: 48px;
    cursor: pointer; }

.section-newsletter {
  background: url(./assets/images/newsletter-bg.png);
  background-size: cover;
  padding: 60px 0;
  margin: 0 0 60px 0; }
  .section-newsletter .title {
    text-align: center; }
    .section-newsletter .title h2 {
      color: #FFFFFF; }
      .section-newsletter .title h2::after {
        content: " ";
        display: block;
        width: 100px;
        height: 4px;
        background-color: #FFFFFF;
        margin: 22px auto 36px auto; }
      .section-newsletter .title h2 > span {
        color: #FFFFFF; }
    .section-newsletter .title p {
      color: #FFFFFF;
      font-size: 18px;
      margin-bottom: 38px; }
  @media only screen and (max-width: 768px) {
    .section-newsletter > div {
      text-align: center; } }
  .section-newsletter input {
    border-radius: 6px;
    height: 50px;
    border: 1px solid #CCCCCC;
    outline: none;
    padding: 0 20px;
    width: 70%;
    font-size: 18px; }
    @media only screen and (max-width: 768px) {
      .section-newsletter input {
        width: 100%; } }
  .section-newsletter .button {
    height: 50px;
    margin-left: 20px;
    background-color: #fff;
    color: #333333;
    font-weight: 600;
    border-radius: 6px; }
    @media only screen and (max-width: 768px) {
      .section-newsletter .button {
        margin-top: 20px;
        margin-left: 0; } }

.section-articles a {
  text-decoration: none; }

.error-text {
  margin: 16px 0;
  color: #DB3838; }

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

/* Track */
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2);
  border-radius: rem-calc(5); }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ED3237;
  border-radius: rem-calc(5);
  cursor: pointer; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ED3237;
  cursor: pointer; }

.styled-numbers {
  margin-bottom: 20px;
  list-style: none; }
  .styled-numbers span {
    font-weight: 600;
    color: #ED3237;
    margin-right: 8px; }

.text-primary {
  color: #ED3237 !important; }

.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #FFFFFF;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.05); }
  .cookies P {
    color: #333333;
    margin-bottom: 0; }
  .cookies button {
    margin: 0 16px;
    border: 0;
    background-color: #333333;
    color: #FFFFFF;
    padding: 6px 16px; }
    .cookies button:hover {
      background-color: #ED3237; }
  .cookies a {
    color: #333333;
    font-weight: 600;
    font-size: 18px; }
  @media only screen and (max-width: 768px) {
    .cookies {
      flex-direction: column;
      height: unset;
      padding: 32px 16px; }
      .cookies button {
        margin-top: 16px; } }

.pop-up-messages {
  position: fixed;
  right: 32px;
  top: 275px;
  z-index: 999999;
  width: 400px; }
  @media only screen and (max-width: 768px) {
    .pop-up-messages {
      width: 90%;
      margin-left: 5%;
      top: 0;
      margin-top: 50px;
      left: 0;
      right: unset; } }
  .pop-up-messages > div {
    padding: 42px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    margin-bottom: 16px;
    position: relative;
    cursor: pointer; }
    .pop-up-messages > div .hide {
      color: #FFFFFF;
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 22px;
      cursor: pointer; }
    .pop-up-messages > div > p {
      color: #FFFFFF;
      display: flex;
      align-items: center;
      margin-bottom: 0; }
      .pop-up-messages > div > p::before {
        content: ' ';
        display: block;
        width: 10px;
        height: 20px;
        border-bottom: 3px solid #97ef9d;
        border-right: 3px solid #97ef9d;
        transform: rotate(45deg);
        float: left;
        margin-right: 16px; }
    .pop-up-messages > div .error::before {
      display: none; }
  .pop-up-messages .hide-message {
    animation-name: hide-message;
    animation-duration: 1s;
    transform: translate3d(110%, 0, 0); }

@keyframes hide-message {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(110%, 0, 0); } }
  .pop-up-messages .mdi-close {
    color: #DB3838;
    font-size: 24px;
    margin-right: 8px; }

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 800px;
  height: 520px;
  margin-left: -400px;
  margin-top: -260px;
  -webkit-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  z-index: 21;
  animation-name: rotate;
  animation-duration: 1s; }

@keyframes rotate {
  from {
    top: 0; }
  to {
    top: 50%; } }
  @media only screen and (max-width: 768px) {
    .popup {
      width: 90%;
      margin-left: 5%;
      left: 0;
      height: 400px; } }
  .popup .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .popup .content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    padding: 64px; }
    @media only screen and (max-width: 768px) {
      .popup .content {
        padding: 36px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; } }
    .popup .content .coupon {
      width: 425px;
      transform: rotate(-6deg);
      margin-bottom: 40px; }
      @media only screen and (max-width: 768px) {
        .popup .content .coupon {
          width: 70%; } }
      @media only screen and (max-width: 768px) {
        .popup .content .coupon {
          margin-bottom: 10px; } }
    .popup .content h2 {
      font-size: 46px;
      color: #FFFFFF; }
      @media only screen and (max-width: 768px) {
        .popup .content h2 {
          font-size: 24px; } }
    .popup .content h3 {
      font-size: 24px;
      color: #FFFFFF; }
      @media only screen and (max-width: 768px) {
        .popup .content h3 {
          font-size: 18px; } }
    .popup .content h6 {
      font-size: 15px;
      color: #CCCCCC;
      font-style: italic;
      margin-bottom: 20px; }
      @media only screen and (max-width: 768px) {
        .popup .content h6 {
          font-size: 12px; } }
    .popup .content .button {
      position: absolute;
      bottom: 120px;
      left: 50%;
      margin-left: -120px;
      background-color: #000; }

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 19;
  background-color: rgba(0, 0, 0, 0.3); }

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px; }

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #ED3237;
  border-color: #ED3237 transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite; }

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.not-found-content {
  text-align: center;
  padding: 38px 0; }
  .not-found-content h1 {
    font-size: 100px !important; }
  .not-found-content h2 {
    font-size: 32px !important;
    margin-bottom: 32px; }

article {
  position: relative; }
  article .discount {
    position: absolute;
    right: 0;
    top: 20px;
    background-color: #B3DE5B;
    color: #FFFFFF;
    padding: 5px 10px;
    border-radius: 6px 0 0 6px;
    font-weight: 600;
    font-size: 18px; }
    @media only screen and (max-width: 768px) {
      article .discount {
        right: 0px; } }

.list-article .discount {
  right: 0; }

.discount-detail {
  position: absolute;
  right: 15px;
  top: 20px;
  background-color: #B3DE5B;
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 6px 0 0 6px;
  font-weight: 600;
  font-size: 18px;
  z-index: 19; }

.news-detail h1 {
  margin-bottom: 24px; }

.news-detail img {
  width: 100%;
  margin-bottom: 16px; }

.left-anim {
  position: absolute;
  z-index: -999;
  top: 266px;
  left: 81px;
  width: 198px;
  height: 100%; }
  @media only screen and (max-width: 768px) {
    .left-anim {
      display: none; } }
  .left-anim img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.right-anim {
  z-index: -999;
  width: 198px;
  position: absolute;
  top: 317px;
  right: 107px;
  height: 100%; }
  @media only screen and (max-width: 768px) {
    .right-anim {
      display: none; } }
  .right-anim img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.button {
  color: #FFFFFF;
  background-color: #ED3237;
  border-radius: 6px;
  padding: 8px 28px;
  text-decoration: none;
  outline: none;
  border: none; }
  .button .isvg {
    margin-right: 8px; }

.button-black {
  background-color: #333333; }

.button-right {
  float: right; }

.big-button {
  height: 50px; }

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
  header .header-top {
    width: 100%;
    height: 45px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #C5C5C5;
    color: #333333;
    padding: 6px 0; }
    @media only screen and (max-width: 1199px) {
      header .header-top {
        display: none; } }
    header .header-top .header-top-container {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    header .header-top .social {
      padding: 0;
      text-align: right;
      font-size: 16px; }
      header .header-top .social .isvg {
        margin: 0 10px; }
        header .header-top .social .isvg svg {
          fill: #333333; }
    header .header-top .actions {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      header .header-top .actions a {
        margin-right: 32px;
        color: #333333; }
        header .header-top .actions a .isvg {
          margin-right: 10px; }
        header .header-top .actions a svg {
          fill: #333333; }
        header .header-top .actions a:hover {
          color: #333333;
          text-decoration: none; }
          header .header-top .actions a:hover svg {
            fill: #333333; }
  header .header {
    padding-top: 24px;
    padding-bottom: 24px;
    position: relative; }
    @media only screen and (max-width: 991px) {
      header .header {
        padding-top: 18px;
        padding-bottom: 18px; } }
    header .header > .row {
      align-items: center;
      position: relative; }
    header .header .mobile-hamburger {
      display: none; }
      @media only screen and (max-width: 991px) {
        header .header .mobile-hamburger {
          padding: 0;
          display: flex;
          align-items: center; }
          header .header .mobile-hamburger svg {
            fill: #333333; } }
    header .header .mobile-actions {
      padding-right: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      @media only screen and (min-width: 1200px) {
        header .header .mobile-actions {
          display: none; } }
      header .header .mobile-actions button {
        background-color: transparent;
        outline: none;
        border: 0;
        margin: 0 3px; }
      header .header .mobile-actions svg {
        fill: #333333; }
      header .header .mobile-actions a {
        margin: 0 3px; }
        header .header .mobile-actions a svg {
          fill: none;
          stroke: #333333; }
    header .header .hamburger {
      display: none; }
      @media only screen and (max-width: 1200px) {
        header .header .hamburger {
          display: flex !important;
          align-items: center; }
          header .header .hamburger svg {
            fill: #333333; } }
    header .header .logo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative; }
      header .header .logo img {
        max-height: 52px; }
        @media only screen and (max-width: 1200px) {
          header .header .logo img {
            width: 100%; } }
    header .header .search-form {
      position: relative;
      display: flex;
      align-items: center; }
      header .header .search-form input {
        height: 45px;
        border-radius: 6px;
        border: 1px solid #CCCCCC;
        background-color: transparent;
        width: 100%;
        padding: 0 120px 0 20px;
        outline: none;
        color: #333333; }
        @media only screen and (max-width: 991px) {
          header .header .search-form input {
            height: 37px;
            padding: 0 20px 0 20px; } }
      header .header .search-form .mdi-magnify {
        font-size: 24px;
        position: absolute;
        right: 20px; }
      header .header .search-form button {
        background-color: transparent;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border: none;
        height: 45px;
        position: absolute;
        right: 0;
        color: #FFFFFF;
        font-size: 18px;
        padding: 0 35px; }
        @media only screen and (max-width: 991px) {
          header .header .search-form button {
            display: none; } }
      header .header .search-form .search-results {
        position: absolute;
        top: 70px;
        width: 100%;
        background-color: #FFFFFF;
        padding: 16px 0;
        z-index: 9;
        -webkit-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1); }
        @media only screen and (max-width: 991px) {
          header .header .search-form .search-results {
            top: 40px;
            width: 300px; } }
        header .header .search-form .search-results h6 {
          font-size: 20px;
          padding: 0 16px; }
        header .header .search-form .search-results ul {
          padding: 0;
          list-style: none; }
          header .header .search-form .search-results ul li {
            padding: 8px 16px;
            cursor: pointer;
            float: left;
            width: 100%; }
            @media only screen and (max-width: 991px) {
              header .header .search-form .search-results ul li {
                padding: 8px 4px;
                font-size: 12px; } }
            header .header .search-form .search-results ul li img {
              width: 60px;
              margin-right: 16px;
              height: 60px;
              object-fit: contain; }
              @media only screen and (max-width: 991px) {
                header .header .search-form .search-results ul li img {
                  width: 24px;
                  height: 24px;
                  margin-right: 6px; } }
            header .header .search-form .search-results ul li a {
              color: #333333;
              width: 100%;
              height: 100%;
              float: left;
              text-decoration: none; }
            header .header .search-form .search-results ul li:hover {
              background-color: #eeeeee; }
    header .header .account {
      display: flex !important;
      align-items: center; }
      header .header .account .account-button {
        display: flex;
        align-items: center;
        background-color: #ED3237;
        border-radius: 6px; }
      @media only screen and (max-width: 1200px) {
        header .header .account {
          color: transparent;
          display: none !important; } }
      header .header .account .isvg {
        margin-right: 5px;
        margin-left: 10px; }
        @media only screen and (max-width: 991px) {
          header .header .account .isvg {
            margin-right: 0;
            margin-left: 0; } }
        header .header .account .isvg svg {
          fill: #FFFFFF; }
      header .header .account a {
        color: #333333;
        font-size: 18px;
        font-weight: 600;
        margin: 0 5px;
        text-decoration: none; }
        @media only screen and (max-width: 991px) {
          header .header .account a {
            display: none; } }
      header .header .account .account-text {
        flex-direction: column;
        display: flex; }
        header .header .account .account-text a {
          color: #FFFFFF; }
          header .header .account .account-text a:nth-child(2) {
            font-size: 16px;
            font-weight: 300;
            margin-top: -5px; }
      header .header .account .cart-button {
        position: relative;
        float: left;
        height: 46px;
        border-radius: 6px;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 6px;
        float: right;
        margin: 0;
        margin-left: auto;
        background-color: #ED3237;
        border-radius: 6px; }
        @media only screen and (max-width: 991px) {
          header .header .account .cart-button {
            background-color: #FFFFFF;
            height: 37px;
            padding: 0; } }
        header .header .account .cart-button .cart-text p {
          color: #FFFFFF;
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 500; }
          header .header .account .cart-button .cart-text p:nth-child(2) {
            font-size: 12px;
            font-weight: 400; }
        header .header .account .cart-button .number {
          position: absolute;
          top: 6px;
          right: 8px;
          border-radius: 50%;
          background-color: #FFFFFF;
          color: #333333;
          width: 20px;
          height: 20px;
          font-size: 14px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center; }
          @media only screen and (max-width: 991px) {
            header .header .account .cart-button .number {
              display: none; } }
        header .header .account .cart-button svg {
          fill: none;
          stroke: #FFFFFF;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 2px; }
          @media only screen and (max-width: 991px) {
            header .header .account .cart-button svg {
              stroke: #333333; } }
    header .header .cart {
      display: flex;
      align-items: center; }
      header .header .cart .cart-button {
        position: relative;
        float: left;
        width: 100%;
        height: 50px;
        border-radius: 6px;
        background-color: transparent;
        border: 1px solid #CCCCCC;
        display: flex;
        align-items: center;
        padding: 0 10px; }
        @media only screen and (max-width: 991px) {
          header .header .cart .cart-button {
            background-color: #FFFFFF;
            height: 37px;
            padding: 0; } }
        header .header .cart .cart-button .number {
          position: absolute;
          top: 6px;
          right: 8px;
          border-radius: 50%;
          background-color: #FFFFFF;
          color: #333333;
          width: 20px;
          height: 20px;
          font-size: 14px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center; }
          @media only screen and (max-width: 991px) {
            header .header .cart .cart-button .number {
              display: none; } }
        header .header .cart .cart-button svg {
          fill: none;
          stroke: #FFFFFF;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 2px; }
          @media only screen and (max-width: 991px) {
            header .header .cart .cart-button svg {
              stroke: #333333; } }
    header .header .cart-button-mobile {
      position: relative;
      float: left;
      width: 100%;
      height: 50px;
      background-color: transparent;
      display: flex;
      align-items: center;
      padding: 0 10px; }
      @media only screen and (max-width: 991px) {
        header .header .cart-button-mobile {
          height: 37px;
          padding: 0; } }
      header .header .cart-button-mobile .number {
        position: absolute;
        top: 6px;
        right: 8px;
        border-radius: 50%;
        background-color: #FFFFFF;
        color: #333333;
        width: 20px;
        height: 20px;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center; }
        @media only screen and (max-width: 991px) {
          header .header .cart-button-mobile .number {
            display: none; } }
      header .header .cart-button-mobile svg {
        fill: none;
        stroke: #FFFFFF;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px; }
        @media only screen and (max-width: 991px) {
          header .header .cart-button-mobile svg {
            stroke: #FFFFFF; } }
  header .spacer {
    width: 100%;
    height: 1px;
    background-color: #CCCCCC; }
  header .navigation {
    list-style: none;
    padding: 0;
    margin-left: -30px; }
    @media only screen and (max-width: 1200px) {
      header .navigation {
        display: none; } }
    header .navigation > li {
      float: left;
      position: relative; }
      header .navigation > li a {
        float: left;
        color: #333333;
        font-size: 18px;
        font-weight: 500;
        padding: 14px 30px;
        text-decoration: none;
        text-transform: uppercase; }
      header .navigation > li ul {
        width: 400px;
        display: none;
        position: absolute;
        background-color: #FFFFFF;
        list-style: none;
        top: 55px;
        padding: 0;
        margin: 0;
        -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
        header .navigation > li ul > li {
          padding: 5px 0; }
          header .navigation > li ul > li a:hover {
            color: #ED3237; }
      header .navigation > li:hover ul {
        display: block; }
      header .navigation > li:hover::after {
        content: ' ';
        position: absolute;
        display: block;
        bottom: 0;
        width: 100%;
        background-color: #ED3237;
        height: 4px; }
  header .contact-phone {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    color: #333333;
    font-weight: 500;
    font-size: 16px; }
    @media only screen and (max-width: 1119px) {
      header .contact-phone {
        display: none; } }
    header .contact-phone .isvg {
      margin-right: 8px; }

.mobile-left-menu {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(-110%, 0, 0);
  -moz-transform: translate3d(-110%, 0, 0);
  transform: translate3d(-110%, 0, 0); }
  .mobile-left-menu .content {
    width: 300px;
    height: 100%;
    background-color: #FFFFFF;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(-110%, 0, 0);
    -moz-transform: translate3d(-110%, 0, 0);
    transform: translate3d(-110%, 0, 0);
    -webkit-transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    -moz-transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    cursor: pointer; }
    .mobile-left-menu .content .user-area {
      width: 100%;
      height: 160px;
      background-color: #ED3237;
      padding: 30px 30px; }
      .mobile-left-menu .content .user-area .user-image {
        width: 64px;
        height: 64px;
        background-color: rgba(255, 255, 255, 0.5);
        margin-bottom: 10px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center; }
        .mobile-left-menu .content .user-area .user-image svg {
          fill: #FFFFFF; }
      .mobile-left-menu .content .user-area p {
        color: #FFFFFF;
        margin-bottom: 0;
        font-size: 14px; }
        .mobile-left-menu .content .user-area p:nth-child(2) {
          font-weight: 600; }
        .mobile-left-menu .content .user-area p a {
          color: #FFFFFF; }
    .mobile-left-menu .content ul {
      padding: 25px;
      list-style: none; }
      .mobile-left-menu .content ul > li {
        padding: 8px 0;
        font-weight: 600;
        color: #333333; }
        .mobile-left-menu .content ul > li a {
          color: #333333;
          display: flex;
          align-items: center; }
        .mobile-left-menu .content ul > li svg {
          height: 24px;
          width: 24px;
          margin-right: 10px; }
        .mobile-left-menu .content ul > li i {
          font-size: 24px;
          margin-right: 10px; }

.mobile-left-menu-open {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }
  .mobile-left-menu-open .content {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }

.mobile-right-menu {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(110%, 0, 0);
  -moz-transform: translate3d(110%, 0, 0);
  transform: translate3d(110%, 0, 0);
  cursor: pointer; }
  .mobile-right-menu .content {
    width: 300px;
    height: 100%;
    float: right;
    background-color: #FFFFFF;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(110%, 0, 0);
    -moz-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0);
    -webkit-transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    -moz-transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1); }
    .mobile-right-menu .content h3 {
      background-color: #ED3237;
      color: #FFFFFF;
      padding: 15px 22px;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0; }
    .mobile-right-menu .content > ul {
      list-style: none;
      padding: 20px 16px;
      margin: 0;
      border-top: none;
      float: left;
      width: 100%;
      height: 100%;
      overflow: auto; }
      .mobile-right-menu .content > ul > li {
        border-bottom: 1px solid #CCCCCC;
        width: 100%;
        float: left; }
        .mobile-right-menu .content > ul > li::after {
          /* content: " ";
                    display: block;
                    border-top: 2px solid $gray;
                    border-right: 2px solid $gray;
                    width: 10px;
                    height: 10px;
                    transform: rotate(45deg);
                    float: right;
                    margin-top: 16px;
                    margin-right: 4px;*/ }
        .mobile-right-menu .content > ul > li a {
          float: left;
          color: #333333;
          padding: 7px 0; }
        .mobile-right-menu .content > ul > li > a {
          font-size: 18px; }
        .mobile-right-menu .content > ul > li ul {
          width: 100%;
          float: left;
          list-style: none;
          padding-left: 14px;
          position: relative; }
          .mobile-right-menu .content > ul > li ul::after {
            content: " ";
            display: block;
            border-bottom: 2px solid #ED3237;
            border-left: 2px solid #ED3237;
            width: 8px;
            height: 8px;
            margin-top: 14px;
            position: absolute;
            top: 0;
            left: 0; }
          .mobile-right-menu .content > ul > li ul li {
            width: 100%;
            float: left; }
      .mobile-right-menu .content > ul .active {
        position: relative; }
        .mobile-right-menu .content > ul .active::after {
          position: absolute;
          top: 0;
          right: 0;
          transform: rotate(135deg); }

.mobile-right-menu-open {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }
  .mobile-right-menu-open .content {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }

/*
@include mQ(767px, 320px) {
    .header-top {
        display: none;
    }
    .home-wrap {
        .header {
            display: none;
        }
        .contact-phone {
            display: none;
        }
    }
    .navigation {
        display: none;
    }
}

@include mQ(1024px, 768px) {
    .header-top {
        display: none;
    }
    .home-wrap {
        .header {
            display: none;
        }
        .contact-phone {
            display: none;
        }
    }
    .navigation {
        display: none;
    }
}*/
.search-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #ED3237; }
  .search-container .input {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    margin: 0 auto;
    width: 100%;
    margin-top: -15px; }
    .search-container .input button {
      background-color: transparent;
      border: 0;
      outline: none; }
      .search-container .input button svg {
        stroke: #FFFFFF !important; }
    .search-container .input input {
      background-color: transparent;
      border: 0;
      border-bottom: 2px solid #FFFFFF;
      color: #FFFFFF;
      outline: none; }
  .search-container .close {
    position: absolute;
    right: 16px;
    padding: 16px;
    font-size: 32px;
    background-color: transparent;
    border: 0;
    outline: none; }
    .search-container .close i {
      color: #FFFFFF; }

.footer {
  position: relative;
  overflow: hidden;
  padding-bottom: 32px;
  width: 100%;
  float: left; }
  .footer h6 {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    width: 100%; }
  .footer .nav {
    width: 100%; }
    .footer .nav ul {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
      float: left; }
      .footer .nav ul > li {
        width: 100%;
        float: left;
        padding: 4px 0; }
        .footer .nav ul > li a {
          color: #333333; }
  .footer .footer-top {
    display: flex;
    align-items: center;
    margin-bottom: 42px; }
  .footer .contact-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px; }
    @media only screen and (max-width: 768px) {
      .footer .contact-container {
        flex-direction: column;
        align-items: flex-start; }
        .footer .contact-container > div {
          margin-bottom: 8px; } }
  .footer .logo {
    width: 200px;
    margin-bottom: 49px; }
    .footer .logo > img {
      width: 100%; }
  .footer .navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0; }
    @media only screen and (max-width: 768px) {
      .footer .navigation {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 16px; } }
    .footer .navigation li {
      font-size: 18px; }
      .footer .navigation li a {
        color: #333333;
        font-weight: 400;
        text-decoration: none; }
        .footer .navigation li a:hover {
          font-weight: 600;
          color: #ED3237; }
  .footer .description {
    margin-bottom: 40px;
    font-size: 16px;
    color: #333333; }
  .footer .social {
    color: #333333;
    font-size: 16px; }
    .footer .social .isvg {
      margin: 0 6px; }
  .footer .contact {
    font-size: 14px;
    color: #333333;
    margin-bottom: 48px; }
    .footer .contact i {
      color: #ED3237;
      font-size: 16px; }
    .footer .contact p {
      font-size: 20px;
      font-weight: 600;
      color: #333333; }
  .footer .spacer {
    width: 100%;
    float: left;
    height: 1px;
    background: #CCCCCC;
    position: relative;
    margin: 0 15px;
    margin-bottom: 35px; }
  .footer .copyright {
    color: #333333;
    font-size: 16px;
    width: 100%;
    margin: 0 15px; }
    .footer .copyright a {
      color: #333333; }
    .footer .copyright > p {
      width: 50%;
      float: left; }
      .footer .copyright > p > span {
        font-weight: 600; }
      .footer .copyright > p:nth-child(2) {
        text-align: right; }
  .footer .info {
    text-align: right; }
    .footer .info h6 {
      color: #333333;
      font-size: 22px;
      font-weight: 600; }
    .footer .info p {
      color: #CCCCCC;
      font-size: 18px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .footer .description {
    margin-bottom: 30px !important; }
  .footer .contact {
    margin-bottom: 30px !important; }
  .footer .nav {
    margin-bottom: 30px !important; }
  .footer .social {
    margin-bottom: 30px !important; } }

.select-field {
  border: 1px solid #D1D1D1;
  background-color: #FFFFFF;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 14px; }
  .select-field a {
    color: #333333; }
  .select-field .dropdown-menu {
    width: 100%;
    max-height: 300px;
    overflow: auto; }
  .select-field .dropdown-toggle::after {
    display: none; }
  .select-field .dropdown-toggle::before {
    content: " ";
    display: block;
    border-top: 2px solid #333333;
    border-right: 2px solid #333333;
    width: 10px;
    height: 10px;
    transform: rotate(135deg);
    float: right;
    margin-top: 6px; }

.input-wrap {
  width: 100%; }
  .input-wrap input[type=text], .input-wrap input[type=email], .input-wrap input[type=password] {
    height: 50px;
    width: 100%;
    max-width: 500px;
    border-radius: 6px;
    border: 1px solid #D1D1D1;
    padding: 0 16px; }
  .input-wrap textarea {
    height: 150px;
    width: 100%;
    max-width: 500px;
    border-radius: 6px;
    border: 1px solid #D1D1D1;
    padding: 0 16px; }

.input-wrap {
  margin-bottom: 20px; }
  .input-wrap label {
    width: 100%;
    color: #333333;
    font-size: 18px; }
  .input-wrap .required {
    border: 1px solid #DB3838 !important; }
  .input-wrap .select-field {
    width: 100%;
    max-width: 500px;
    height: 50px; }
    .input-wrap .select-field .dropdown-toggle {
      height: 50px !important; }
  .input-wrap .checkbox {
    border: 2px solid #D1D1D1;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 8px;
    border-radius: 4px;
    float: left; }
  .input-wrap .checked {
    display: flex;
    align-items: center;
    justify-content: center; }
    .input-wrap .checked::after {
      content: " ";
      display: block;
      border-right: 3px solid #ED3237;
      border-bottom: 3px solid #ED3237;
      width: 6px;
      height: 12px;
      transform: rotate(45deg); }
  .input-wrap .radio-button {
    border: 2px solid #D1D1D1;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 8px;
    border-radius: 50%;
    float: left; }
  .input-wrap .radio-button-checked {
    display: flex;
    align-items: center;
    justify-content: center; }
    .input-wrap .radio-button-checked::after {
      content: " ";
      display: block;
      width: 10px;
      height: 10px;
      background-color: #ED3237;
      border-radius: 50%; }

.radio-button-wrap {
  display: flex;
  align-items: center;
  cursor: pointer; }

.sort-form {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  @media only screen and (max-width: 768px) {
    .sort-form {
      justify-content: space-between;
      padding-bottom: 15px; } }
  .sort-form > span {
    font-size: 14px;
    margin-right: 12px; }
  .sort-form .select-field {
    height: 40px;
    width: 200px;
    margin-right: 28px; }
    @media only screen and (max-width: 768px) {
      .sort-form .select-field {
        margin-right: 0; } }
  .sort-form .active {
    background-color: #ED3237;
    border-radius: 0; }
  @media only screen and (max-width: 768px) {
    .sort-form .btn {
      display: none; } }

.newsletter-form {
  font-size: 18px; }
  .newsletter-form .button {
    margin-right: 16px; }

.article {
  text-decoration: none !important; }
  .article article {
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    min-height: 345px; }
    @media only screen and (max-width: 768px) {
      .article article {
        min-height: 198px; } }
    .article article:hover {
      -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.2s ease-in-out; }
    .article article img {
      padding: 30px;
      width: 100%;
      height: 230px;
      object-fit: contain;
      border-bottom: 1px solid #CCCCCC; }
      @media only screen and (max-width: 768px) {
        .article article img {
          height: 100px;
          padding: 5px; } }
    .article article h6 {
      margin: 14px 20px 11px 20px;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      color: #333333;
      text-decoration: none !important;
      height: 44px;
      overflow: hidden; }
      @media only screen and (max-width: 768px) {
        .article article h6 {
          font-size: 16px;
          margin: 14px 10px 10px 10px;
          height: 42px; } }
    .article article p {
      padding: 0 20px 14px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none !important;
      font-size: 18px;
      font-weight: 600;
      color: #ED3237;
      text-align: center;
      justify-content: center; }
      @media only screen and (max-width: 768px) {
        .article article p {
          padding: 0 10px 10px 10px;
          font-size: 18px; } }
      .article article p .package {
        font-size: 16px; }

@media only screen and (max-width: 768px) {
  .article-container:nth-child(2n) {
    padding-left: 7.5px; }
  .article-container:nth-child(2n+1) {
    padding-right: 7.5px; } }

@media only screen and (max-width: 768px) {
  .article-container1:nth-child(2n) {
    padding-right: 7.5px; }
  .article-container1:nth-child(2n+1) {
    padding-left: 7.5px; } }

.list-article {
  text-decoration: none !important; }
  .list-article article {
    background-color: #FFFFFF;
    margin-bottom: 15px;
    width: 100%;
    height: 160px;
    display: flex; }
    .list-article article:hover {
      -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.2s ease-in-out; }
    .list-article article img {
      width: 200px;
      max-width: 200px;
      min-width: 200px;
      height: 160px;
      object-fit: contain;
      float: left;
      padding: 16px; }
    .list-article article > div {
      float: left;
      padding: 16px;
      width: 100%;
      position: relative; }
      .list-article article > div h6 {
        margin: 14px 20px 11px 20px;
        font-size: 22px;
        font-weight: 600;
        color: #333333;
        text-decoration: none !important;
        height: 44px;
        overflow: hidden; }
      .list-article article > div p {
        position: absolute;
        bottom: 16px;
        width: 100%;
        padding: 0 40px 0px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none !important;
        margin-bottom: 0; }
        .list-article article > div p .price {
          color: #ED3237;
          font-size: 18px;
          font-weight: 600; }
        .list-article article > div p .in-stock {
          margin-left: auto;
          font-size: 16px;
          color: #333333;
          display: flex;
          align-items: center; }
          .list-article article > div p .in-stock::after {
            content: " ";
            display: block;
            background-color: #97EF9D;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            float: right;
            margin-left: 10px; }

.alert {
  background-color: #DDF9EB;
  border: 1px solid #ED3237;
  font-size: 18px;
  min-height: 50px;
  margin-bottom: 34px;
  padding: 10px 18px;
  border-radius: 6px; }

.car-animation {
  position: relative;
  animation-name: car-animation;
  animation-duration: 2s; }

@keyframes car-animation {
  from {
    top: -50px;
    right: -80px;
    transform: scale(0.7); }
  to {
    top: 0px;
    right: 0;
    transform: scale(1); } }

.top-bottom-animation {
  position: relative;
  animation-name: top-bottom;
  animation-duration: 1s; }

@keyframes top-bottom {
  from {
    top: -200px; }
  to {
    top: 0px; } }

.dropdown-animation {
  animation-name: dropdown-animation;
  animation-duration: 0.2s; }

@keyframes dropdown-animation {
  from {
    transform: translateY(60px);
    opacity: 0; }
  to {
    transform: translateY(48px);
    opacity: 1; } }

@keyframes bubble-animation {
  0% {
    border: 10px solid rgba(255, 255, 255, 0); }
  50% {
    border: 20px solid rgba(255, 255, 255, 0.5); }
  100% {
    border: 10px solid rgba(255, 255, 255, 0); } }

.bubble-animation {
  animation-iteration-count: infinite;
  animation-name: bubble-animation;
  animation-duration: 2s; }

@keyframes active-menu-animation {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(70%, 10vh, 0); } }

.active-menu-animation {
  transform: translate3d(70%, 10vh, 0);
  position: fixed;
  width: 100% !important;
  position: fixed !important;
  height: 80vh !important;
  overflow-x: auto;
  overflow-y: scroll;
  animation-name: active-menu-animation;
  animation-duration: 1s;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16); }

.home-wrap .main {
  padding-top: 40px;
  padding-bottom: 20px; }
  .home-wrap .main h1 {
    display: none; }

.home-wrap .categories {
  background-color: #FFFFFF;
  float: left;
  width: 100%;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
  .home-wrap .categories .submenu {
    display: none; }
  .home-wrap .categories h3 {
    background-color: #ED3237;
    color: #FFFFFF;
    padding: 12px 22px;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0; }
  .home-wrap .categories > ul {
    min-height: 509px; }
  .home-wrap .categories ul {
    width: 100%;
    list-style: none;
    padding: 6px 16px;
    margin: 0;
    border-top: none;
    float: left; }
    .home-wrap .categories ul > li {
      width: 100%;
      float: left;
      border-bottom: 1px solid #CCCCCC; }
      .home-wrap .categories ul > li::after {
        content: " ";
        display: block;
        border-top: 2px solid #ED3237;
        border-right: 2px solid #ED3237;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        float: right;
        margin-top: 16px;
        margin-right: 5px; }
      .home-wrap .categories ul > li:last-child {
        border-bottom: 0; }
      .home-wrap .categories ul > li > a {
        float: left;
        color: #333333;
        padding: 6px 0;
        width: 90%;
        /*width: 100%;
                    border-bottom: 1px solid $gray;
                    &:last-child {
                        border-bottom: none;
                    }*/ }
      .home-wrap .categories ul > li:hover .submenu {
        height: 100%;
        position: absolute;
        left: 240px;
        background: white;
        z-index: 16;
        top: 0;
        padding-right: 40px;
        /*border-top: 3px solid $primary;
                        border-bottom: 3px solid $primary;
                        border-right: 3px solid $primary;
                        */
        display: flex;
        -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        flex-wrap: wrap;
        flex-direction: column;
        min-width: 450px; }
        .home-wrap .categories ul > li:hover .submenu h5 {
          font-size: 22px;
          font-weight: 600;
          line-height: 26px;
          color: #333333;
          margin-left: 13px;
          margin-top: 15px; }
        .home-wrap .categories ul > li:hover .submenu li {
          margin-left: 13px;
          float: none;
          display: flex;
          justify-content: space-between;
          width: 236px; }
          .home-wrap .categories ul > li:hover .submenu li:after {
            margin-right: 0px; }
          .home-wrap .categories ul > li:hover .submenu li:last-child {
            border-bottom: 1px solid #CCCCCC;
            margin-bottom: 14px; }
        .home-wrap .categories ul > li:hover .submenu ul {
          border: none; }

.home-wrap .home-slider {
  height: 530px;
  overflow: hidden;
  background-color: #FFFFFF; }
  @media only screen and (max-width: 767px) {
    .home-wrap .home-slider {
      height: 220px; } }
  @media only screen and (min-width: 768px) and (max-width: 900px) {
    .home-wrap .home-slider {
      height: 300px; } }
  .home-wrap .home-slider .carousel-indicators {
    left: 0;
    justify-content: flex-end;
    margin: 0 auto;
    max-width: 1140px;
    margin-bottom: 42px; }
    @media only screen and (max-width: 767px) {
      .home-wrap .home-slider .carousel-indicators {
        margin-left: 20px;
        margin-bottom: 0px;
        display: none; } }
    .home-wrap .home-slider .carousel-indicators li {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background-color: #FFFFFF; }
      @media only screen and (max-width: 767px) {
        .home-wrap .home-slider .carousel-indicators li {
          width: 12px;
          height: 12px;
          background-color: #FFFFFF; } }
    .home-wrap .home-slider .carousel-indicators .active {
      border: none;
      background-color: #ED3237; }
  .home-wrap .home-slider .carousel-item {
    position: relative; }
    .home-wrap .home-slider .carousel-item img {
      width: 100%;
      height: 530px;
      background-color: #FFFFFF; }
      @media only screen and (max-width: 767px) {
        .home-wrap .home-slider .carousel-item img {
          height: 220px;
          object-fit: cover;
          object-position: right; } }
      @media only screen and (min-width: 768px) and (max-width: 900px) {
        .home-wrap .home-slider .carousel-item img {
          height: 300px; } }
    .home-wrap .home-slider .carousel-item .content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 23px 50px; }
      @media only screen and (max-width: 767px) {
        .home-wrap .home-slider .carousel-item .content {
          padding: 10px 20px; } }
      .home-wrap .home-slider .carousel-item .content .content-col {
        margin-top: 100px; }
        @media only screen and (max-width: 767px) {
          .home-wrap .home-slider .carousel-item .content .content-col {
            margin-top: 50px; }
            .home-wrap .home-slider .carousel-item .content .content-col .isvg svg {
              width: 32px;
              height: 37px; } }
      .home-wrap .home-slider .carousel-item .content h6 {
        color: #FFFFFF;
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 10px;
        position: relative; }
        @media only screen and (max-width: 767px) {
          .home-wrap .home-slider .carousel-item .content h6 {
            font-size: 12px; } }
      .home-wrap .home-slider .carousel-item .content h3 {
        color: #FFFFFF;
        font-size: 38px;
        font-weight: 700;
        margin-bottom: 10px;
        max-width: 368px;
        position: relative; }
        .home-wrap .home-slider .carousel-item .content h3::after {
          content: ' ';
          display: block;
          height: 2px;
          width: 120px;
          background-color: #FFFFFF;
          margin-top: 15px; }
        @media only screen and (max-width: 767px) {
          .home-wrap .home-slider .carousel-item .content h3 {
            font-size: 16px;
            margin-bottom: 16px;
            max-width: 150px; } }
      .home-wrap .home-slider .carousel-item .content p {
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 35px;
        max-width: 310px; }
        @media only screen and (max-width: 1119px) {
          .home-wrap .home-slider .carousel-item .content p {
            display: none; } }
      .home-wrap .home-slider .carousel-item .content .button {
        color: #FFFFFF;
        background-color: #ED3237;
        border-radius: 6px;
        padding: 15px 28px;
        text-decoration: none;
        font-size: 18px;
        font-weight: 600; }
        @media only screen and (max-width: 767px) {
          .home-wrap .home-slider .carousel-item .content .button {
            font-size: 12px;
            padding: 8px; } }

.home-wrap .banner {
  position: relative;
  height: 210px;
  margin-bottom: 16px; }
  .home-wrap .banner > div {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: 20px;
    left: 0;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 18px;
    padding-right: 18px;
    max-width: 90%; }
    .home-wrap .banner > div h6 {
      font-size: 24px;
      margin-bottom: 0;
      color: #FFFFFF; }
  .home-wrap .banner img {
    height: 100%;
    width: 100%;
    object-fit: cover; }

.home-wrap .section-articles a {
  text-decoration: none; }

.home-wrap .section-reviews .reviews-slider {
  background-color: #ED3237;
  padding-top: 30px;
  padding-bottom: 30px; }
  .home-wrap .section-reviews .reviews-slider .carousel-indicators li {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    background-color: transparent; }
  .home-wrap .section-reviews .reviews-slider .carousel-indicators .active {
    border: none;
    background-color: #FFFFFF; }
  .home-wrap .section-reviews .reviews-slider .review img {
    width: 160px;
    height: 160px;
    border-radius: 50%; }
  .home-wrap .section-reviews .reviews-slider .review p {
    color: #FFFFFF;
    margin-top: 11px;
    font-size: 18px; }
  .home-wrap .section-reviews .reviews-slider .review .user {
    margin-top: 32px;
    font-size: 20px;
    font-weight: 600; }

.home-wrap .section-news {
  padding: 40px 0;
  background-color: #ED3237; }
  .home-wrap .section-news h2 {
    color: #FFFFFF; }
  .home-wrap .section-news .title-leafs svg {
    fill: #FFFFFF !important; }
  .home-wrap .section-news a {
    text-decoration: none; }
  .home-wrap .section-news .news-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .home-wrap .section-news .news-title-container a {
      color: #ED3237; }
  .home-wrap .section-news article {
    /*-webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);*/
    background-color: #FFFFFF;
    padding-bottom: 24px;
    margin-bottom: 16px;
    text-align: center; }
    .home-wrap .section-news article img {
      width: 100%;
      height: 200px;
      margin-bottom: 18px; }
    .home-wrap .section-news article h6 {
      font-size: 22px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 14px;
      padding: 0 20px; }
    .home-wrap .section-news article P {
      font-size: 18px;
      color: #333333;
      margin-bottom: 20px;
      padding: 0 20px; }
    .home-wrap .section-news article a {
      color: #ED3237;
      text-decoration: none;
      font-size: 20px;
      font-weight: 500;
      padding: 0 20px; }
  .home-wrap .section-news .button {
    border-radius: 25px;
    border: 2px solid #FFFFFF;
    background-color: transparent;
    margin: 0 auto; }
  .home-wrap .section-news .btn-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center; }

.slider-container {
  position: relative;
  padding-right: 0;
  padding-left: 0; }
  .slider-container .slick-arrow {
    background-color: #FFFFFF;
    outline: none;
    border: 0;
    color: transparent;
    width: 36px;
    height: 36px;
    position: absolute;
    -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14); }
  .slider-container .slick-next {
    top: -58px;
    right: 15px; }
    .slider-container .slick-next::after {
      content: ' ';
      display: block;
      border-top: 2px solid #333333;
      border-left: 2px solid #333333;
      width: 12px;
      height: 12px;
      transform: rotate(135deg);
      position: absolute;
      top: 12px;
      left: 12px; }
  .slider-container .slick-prev {
    top: -58px;
    right: 71px; }
    .slider-container .slick-prev::after {
      content: ' ';
      display: block;
      border-top: 2px solid #333333;
      border-left: 2px solid #333333;
      width: 12px;
      height: 12px;
      transform: rotate(-45deg);
      position: absolute;
      top: 12px;
      left: 15px; }
  .slider-container .slick-slider {
    overflow: hidden; }
  .slider-container .slick-list .slick-slide {
    float: left; }
    .slider-container .slick-list .slick-slide > div {
      padding: 0 15px; }

.section-banners img {
  width: 100%;
  height: 160px;
  object-fit: cover;
  margin-bottom: 15px; }

.section-categories {
  padding: 50px 0;
  background-color: #FFFFFF; }
  @media only screen and (max-width: 767px) {
    .section-categories {
      padding: 20px 0;
      margin-bottom: 20px; } }
  .section-categories .category-box {
    text-align: center; }
    .section-categories .category-box img {
      width: 100%;
      height: 260px;
      object-position: center;
      object-fit: cover; }
      @media only screen and (max-width: 767px) {
        .section-categories .category-box img {
          border-radius: 50%;
          width: 70px;
          height: 70px; } }
    .section-categories .category-box h3 {
      font-size: 22px;
      font-weight: 600;
      margin-top: 20px;
      color: #ED3237; }
      @media only screen and (max-width: 767px) {
        .section-categories .category-box h3 {
          font-size: 18px; } }
    .section-categories .category-box a {
      text-decoration: none !important; }

.news-item {
  margin-bottom: 30px; }
  .news-item a {
    text-decoration: none; }
  .news-item article {
    text-align: center;
    /*-webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);*/
    background-color: #FFFFFF;
    padding-bottom: 24px; }
    .news-item article img {
      width: 100%;
      height: 200px;
      margin-bottom: 18px; }
    .news-item article h6 {
      font-size: 22px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 14px; }
    .news-item article P {
      font-size: 18px;
      color: #333333;
      margin-bottom: 20px;
      padding: 0 30px; }
    .news-item article a {
      color: #ED3237;
      text-decoration: none;
      font-size: 20px;
      font-weight: 300; }

.category-wrap {
  padding-top: 40px; }
  .category-wrap h1 {
    font-size: 32px;
    font-weight: 600; }
  .category-wrap .pagination {
    float: left; }
    .category-wrap .pagination > li {
      width: 40px;
      height: 40px;
      border-radius: 10px;
      background-color: #E5E5E5;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333333;
      font-weight: 600;
      font-size: 16px;
      margin-right: 10px;
      cursor: pointer; }
      .category-wrap .pagination > li a {
        color: #333333;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }
      .category-wrap .pagination > li:hover {
        color: #FFFFFF;
        text-decoration: none;
        background-color: #ED3237; }
        .category-wrap .pagination > li:hover a {
          text-decoration: none;
          color: #FFFFFF; }
    .category-wrap .pagination .break-me:hover {
      background-color: #E5E5E5;
      cursor: default; }
      .category-wrap .pagination .break-me:hover a {
        color: #333333; }
    .category-wrap .pagination .active {
      color: #FFFFFF;
      background-color: #ED3237; }
      .category-wrap .pagination .active a {
        color: #FFFFFF; }
    .category-wrap .pagination .previous {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center; }
      .category-wrap .pagination .previous::after {
        content: ' ';
        border-top: 0;
        border-right: 0;
        border-bottom: 2px solid #333333;
        border-left: 2px solid #333333;
        padding: 4px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        top: 16px;
        left: 16px; }
      .category-wrap .pagination .previous:hover::after {
        border-bottom: 2px solid #FFFFFF;
        border-left: 2px solid #FFFFFF; }
    .category-wrap .pagination .next {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center; }
      .category-wrap .pagination .next::after {
        content: ' ';
        border-top: 0;
        border-right: 0;
        border-bottom: 2px solid #333333;
        border-left: 2px solid #333333;
        padding: 4px;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        top: 16px;
        left: 16px; }
      .category-wrap .pagination .next:hover::after {
        border-bottom: 2px solid #FFFFFF;
        border-left: 2px solid #FFFFFF; }
  @media only screen and (max-width: 768px) {
    .category-wrap .articles {
      padding: 0; } }
  .category-wrap .categories {
    background-color: #FFFFFF;
    float: left;
    width: 100%; }
    .category-wrap .categories h3 {
      background-color: #ED3237;
      color: #FFFFFF;
      padding: 12px 22px;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 0; }
    .category-wrap .categories > ul {
      width: 100%;
      list-style: none;
      padding: 3px 16px;
      margin: 0;
      border-top: none;
      float: left;
      width: 100%;
      -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
      .category-wrap .categories > ul > li {
        border-bottom: 1px solid #CCCCCC;
        width: 100%;
        float: left;
        position: relative;
        padding-right: 10px; }
        .category-wrap .categories > ul > li::after {
          content: " ";
          display: block;
          border-top: 2px solid #CCCCCC;
          border-right: 2px solid #CCCCCC;
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
          position: absolute;
          top: 16px;
          right: 4px; }
        .category-wrap .categories > ul > li:last-child {
          border-bottom: none; }
        .category-wrap .categories > ul > li a {
          float: left;
          color: #333333;
          padding: 7px 0; }
        .category-wrap .categories > ul > li > a {
          font-size: 16px; }
        .category-wrap .categories > ul > li ul {
          width: 100%;
          float: left;
          list-style: none;
          padding-left: 14px;
          position: relative; }
          .category-wrap .categories > ul > li ul::after {
            content: " ";
            display: block;
            border-bottom: 2px solid #ED3237;
            border-left: 2px solid #ED3237;
            width: 8px;
            height: 8px;
            margin-top: 14px;
            position: absolute;
            top: 0;
            left: 0; }
          .category-wrap .categories > ul > li ul li {
            width: 100%;
            float: left; }
      .category-wrap .categories > ul .active {
        position: relative; }
        .category-wrap .categories > ul .active > a {
          color: #ED3237;
          font-weight: 600; }
        .category-wrap .categories > ul .active::after {
          position: absolute;
          top: 16px;
          right: 4px;
          transform: rotate(135deg); }

.detail-wrap {
  padding-top: 40px; }
  .detail-wrap h1 {
    font-size: 32px;
    font-weight: 600; }
  .detail-wrap .categories {
    background-color: #FFFFFF;
    float: left;
    width: 100%; }
    .detail-wrap .categories h3 {
      background-color: #ED3237;
      color: #FFFFFF;
      padding: 12px 22px;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 0; }
    .detail-wrap .categories > ul {
      width: 100%;
      list-style: none;
      padding: 3px 16px;
      margin: 0;
      border-top: none;
      float: left;
      width: 100%;
      -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
      .detail-wrap .categories > ul > li {
        border-bottom: 1px solid #CCCCCC;
        width: 100%;
        float: left;
        position: relative; }
        .detail-wrap .categories > ul > li::after {
          content: " ";
          display: block;
          border-top: 2px solid #CCCCCC;
          border-right: 2px solid #CCCCCC;
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
          position: absolute;
          top: 16px;
          right: 4px; }
        .detail-wrap .categories > ul > li:last-child {
          border-bottom: none; }
        .detail-wrap .categories > ul > li a {
          float: left;
          color: #333333;
          padding: 7px 0; }
        .detail-wrap .categories > ul > li > a {
          font-size: 16px; }
        .detail-wrap .categories > ul > li ul {
          width: 100%;
          float: left;
          list-style: none;
          padding-left: 14px;
          position: relative; }
          .detail-wrap .categories > ul > li ul::after {
            content: " ";
            display: block;
            border-bottom: 2px solid #ED3237;
            border-left: 2px solid #ED3237;
            width: 8px;
            height: 8px;
            margin-top: 14px;
            position: absolute;
            top: 0;
            left: 0; }
          .detail-wrap .categories > ul > li ul li {
            width: 100%;
            float: left; }
      .detail-wrap .categories > ul .active {
        position: relative; }
        .detail-wrap .categories > ul .active::after {
          position: absolute;
          top: 16px;
          right: 4px;
          transform: rotate(135deg); }
  .detail-wrap .article-box {
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    padding: 40px;
    padding-bottom: 26px; }
    @media only screen and (max-width: 768px) {
      .detail-wrap .article-box {
        padding: 15px;
        padding-bottom: 26px; } }
    .detail-wrap .article-box ul {
      list-style: none; }
    .detail-wrap .article-box table {
      width: 100%;
      overflow-x: auto;
      max-width: 100%; }
      .detail-wrap .article-box table tr th {
        padding: 16px;
        color: #FFFFFF; }
      .detail-wrap .article-box table tr td {
        padding: 16px; }
    .detail-wrap .article-box .variations-table {
      max-width: 100%;
      overflow: auto; }
    .detail-wrap .article-box .variations table tr th {
      font-size: 12px;
      padding: 6px; }
    .detail-wrap .article-box .variations table tr td {
      font-size: 12px;
      padding: 6px; }
    .detail-wrap .article-box h2 {
      font-size: 32px;
      font-weight: 600;
      display: flex;
      justify-content: space-between; }
      @media only screen and (max-width: 768px) {
        .detail-wrap .article-box h2 {
          font-size: 24px; } }
    .detail-wrap .article-box h3 {
      font-size: 18px;
      font-weight: 400; }
      @media only screen and (max-width: 768px) {
        .detail-wrap .article-box h3 {
          font-size: 20px; } }
    .detail-wrap .article-box .stock {
      font-size: 18px;
      color: #333333;
      font-weight: 300;
      display: flex;
      align-items: center;
      margin-bottom: 0; }
      .detail-wrap .article-box .stock .out-of-stock {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #DE3636;
        float: left;
        margin-right: 14px; }
      .detail-wrap .article-box .stock .in-stock {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #97EF9D;
        float: left;
        margin-right: 14px; }
    .detail-wrap .article-box .spacer {
      width: 100%;
      background-color: #CCCCCC;
      height: 1px;
      margin: 18px 0;
      float: left; }
    .detail-wrap .article-box .price {
      width: 100%;
      float: left;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      color: #ED3237;
      font-size: 30px;
      font-weight: 400; }
      .detail-wrap .article-box .price span {
        color: #ED3237;
        font-size: 36px;
        font-weight: 600;
        margin-top: auto; }
    .detail-wrap .article-box .buttons {
      float: left;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-top: 12px;
      align-items: flex-end; }
      .detail-wrap .article-box .buttons input {
        height: 35px;
        width: 60px;
        border-radius: 6px;
        border: 1px solid #D1D1D1;
        padding: 0 16px;
        margin-left: 15px; }
      @media only screen and (max-width: 768px) {
        .detail-wrap .article-box .buttons {
          margin-bottom: 36px; } }
      .detail-wrap .article-box .buttons .button {
        margin-left: 65px;
        font-size: 22px;
        border-radius: 6px;
        font-weight: 600;
        background-color: #ED3237; }
        @media only screen and (max-width: 768px) {
          .detail-wrap .article-box .buttons .button {
            font-size: 20px;
            padding: 8px 10px; } }
    .detail-wrap .article-box .actions {
      width: 100%;
      float: left;
      display: flex;
      justify-content: space-between; }
      .detail-wrap .article-box .actions .add-review {
        max-width: 170px;
        display: flex;
        align-items: center; }
        .detail-wrap .article-box .actions .add-review .button {
          float: left;
          padding: 10px;
          margin-right: 13px; }
        .detail-wrap .article-box .actions .add-review p {
          margin-bottom: 0; }
      .detail-wrap .article-box .actions .ask-question {
        color: #ED3237;
        font-size: 18px;
        text-decoration: underline;
        margin-top: auto; }

.article-image .preview {
  width: 100%;
  object-fit: contain;
  object-position: center;
  height: 405px;
  padding: 15px;
  background-color: #FFFFFF; }
  @media only screen and (max-width: 767px) {
    .article-image .preview {
      height: 250px; } }

.article-image .images {
  width: 100%;
  padding: 10px 0;
  overflow: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  cursor: grab;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }
  @media only screen and (max-width: 768px) {
    .article-image .images {
      margin-bottom: 12px; } }
  .article-image .images .image {
    display: inline-block;
    margin-right: 10px;
    width: 90px;
    height: 110px;
    position: relative;
    padding: 5px;
    background-color: #FFFFFF; }
    .article-image .images .image::after {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7); }
    .article-image .images .image > img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
      .article-image .images .image > img:last-child {
        margin-right: 0; }
  .article-image .images .active {
    position: relative; }
    .article-image .images .active::after {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent; }

.packages {
  margin: 0 -5px;
  margin-top: 12px;
  margin-bottom: 40px;
  width: 100%;
  float: left;
  cursor: pointer; }
  .packages .package {
    background-color: #F6F6F6;
    float: left;
    padding: 7px 18px;
    margin: 0 5px;
    border: 1px solid #CCCCCC;
    margin-bottom: 5px; }
    .packages .package p {
      margin-bottom: 0;
      color: #CCCCCC;
      font-size: 20px; }
  .packages .active {
    background-color: #F6F6F6;
    border: 1px solid #ED3237; }
    .packages .active p {
      margin-bottom: 0;
      color: #333333;
      font-weight: 600;
      font-size: 20px; }

.wishlist-btn {
  background-color: transparent;
  border: 0;
  outline: none; }

.article-detail {
  min-height: 500px; }

.packages-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; }
  .packages-title h6 {
    font-size: 20px;
    font-weight: 400; }

.short-description {
  margin-bottom: 0; }

.detail-quantity h6 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 13px; }

.detail-quantity > div {
  background-color: #F6F6F6;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #CCCCCC;
  margin: 0 -10px;
  height: 50px; }
  .detail-quantity > div button {
    background-color: #FFFFFF;
    border: 0;
    outline: none;
    border-left: 1px solid #CCCCCC;
    height: 48px;
    width: 30px; }
    .detail-quantity > div button:nth-child(1) {
      border-right: 1px solid #CCCCCC;
      border-left: 0; }
  .detail-quantity > div h6 {
    margin-bottom: 0;
    width: 71px;
    text-align: center; }

.about-product-title {
  display: flex;
  align-items: center;
  justify-content: center; }
  .about-product-title > div {
    margin-bottom: 28px;
    position: relative; }
    .about-product-title > div::after {
      content: ' ';
      display: block;
      height: 2px;
      width: 40px;
      background-color: #ED3237;
      position: absolute;
      left: -55px;
      top: 20px; }
    .about-product-title > div::before {
      content: ' ';
      display: block;
      height: 2px;
      width: 40px;
      background-color: #ED3237;
      position: absolute;
      right: -55px;
      top: 20px; }
    .about-product-title > div h6 {
      font-size: 32px;
      font-weight: 600; }

.product-description {
  text-align: left;
  font-size: 20px;
  margin-bottom: 30px; }

.description-title {
  color: #ED3237;
  font-size: 30px;
  font-weight: 600; }
  .description-title::after {
    content: ' ';
    width: 100%;
    height: 1px;
    background-color: #C7C7C7;
    display: block;
    margin-top: 12px;
    margin-bottom: 20px; }

.account-wrap {
  padding-top: 40px; }
  .account-wrap h1 {
    font-size: 32px;
    font-weight: 600; }
  .account-wrap .categories {
    background-color: #FFFFFF;
    float: left;
    width: 100%; }
    .account-wrap .categories h3 {
      background-color: #ED3237;
      color: #FFFFFF;
      padding: 12px 22px;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 0; }
    .account-wrap .categories > ul {
      width: 100%;
      list-style: none;
      padding: 3px 16px;
      margin: 0;
      border-top: none;
      float: left;
      width: 100%;
      -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
      .account-wrap .categories > ul > li {
        border-bottom: 1px solid #CCCCCC;
        width: 100%;
        float: left;
        position: relative; }
        .account-wrap .categories > ul > li::after {
          content: " ";
          display: block;
          border-top: 2px solid #CCCCCC;
          border-right: 2px solid #CCCCCC;
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
          position: absolute;
          top: 16px;
          right: 4px; }
        .account-wrap .categories > ul > li:last-child {
          border-bottom: none; }
        .account-wrap .categories > ul > li a {
          float: left;
          color: #333333;
          padding: 7px 0; }
        .account-wrap .categories > ul > li > a {
          font-size: 16px; }
        .account-wrap .categories > ul > li ul {
          width: 100%;
          float: left;
          list-style: none;
          padding-left: 14px;
          position: relative; }
          .account-wrap .categories > ul > li ul::after {
            content: " ";
            display: block;
            border-bottom: 2px solid #ED3237;
            border-left: 2px solid #ED3237;
            width: 8px;
            height: 8px;
            margin-top: 14px;
            position: absolute;
            top: 0;
            left: 0; }
          .account-wrap .categories > ul > li ul li {
            width: 100%;
            float: left; }
      .account-wrap .categories > ul .active {
        position: relative; }
        .account-wrap .categories > ul .active::after {
          position: absolute;
          top: 16px;
          right: 4px;
          transform: rotate(135deg); }
  .account-wrap .account-info p {
    font-size: 18px; }
  .account-wrap .box-container {
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    padding: 40px;
    padding-bottom: 26px;
    margin-top: 10px; }
    @media only screen and (max-width: 768px) {
      .account-wrap .box-container {
        padding: 15px;
        padding-bottom: 26px; } }
    .account-wrap .box-container h3 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 24px; }
    .account-wrap .box-container h6 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 24px; }
    .account-wrap .box-container .lost-password {
      color: #333333; }
    .account-wrap .box-container table {
      width: 100%;
      border: 1px solid #ED3237; }
      .account-wrap .box-container table tr th {
        background-color: #ED3237;
        padding: 16px;
        color: #FFFFFF; }
      .account-wrap .box-container table tr td {
        border: 1px solid #ED3237;
        padding: 16px; }
    .account-wrap .box-container .button-group {
      padding: 0 0 20px 0; }
      @media only screen and (max-width: 991px) {
        .account-wrap .box-container .button-group {
          padding: 0 15px; } }
      .account-wrap .box-container .button-group .button {
        margin-right: 20px; }
        @media only screen and (max-width: 991px) {
          .account-wrap .box-container .button-group .button {
            margin-right: 0;
            margin-bottom: 16px;
            width: 100%; } }
    .account-wrap .box-container .table {
      border: 1px solid #D1D1D1; }
      @media only screen and (max-width: 991px) {
        .account-wrap .box-container .table {
          margin: 0 15px;
          margin-bottom: 16px; } }
      .account-wrap .box-container .table .row > div {
        padding: 15px; }
        .account-wrap .box-container .table .row > div P {
          margin-bottom: 0;
          font-size: 18px; }
      .account-wrap .box-container .table .header {
        background-color: #ED3237;
        color: #FFFFFF;
        font-weight: 600;
        padding: 0;
        height: 34px; }
        .account-wrap .box-container .table .header > div {
          padding: 0 15px;
          display: flex;
          align-items: center;
          font-size: 14px; }
      .account-wrap .box-container .table .border-row {
        border-bottom: 1px solid #D1D1D1; }
        .account-wrap .box-container .table .border-row:last-child {
          border-bottom: 0; }
        .account-wrap .box-container .table .border-row > div {
          border-right: 1px solid #D1D1D1; }
          .account-wrap .box-container .table .border-row > div:last-child {
            border-right: 0; }
    .account-wrap .box-container .spacer {
      width: 100%;
      background-color: #CCCCCC;
      height: 1px;
      margin: 18px 0;
      float: left; }
    .account-wrap .box-container a {
      text-decoration: none !important; }
      .account-wrap .box-container a .box {
        border: 2px solid #ED3237;
        border-radius: 6px;
        padding: 20px 40px;
        text-align: center;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 20px;
        text-decoration: none !important; }
        .account-wrap .box-container a .box p {
          margin-top: 15px;
          margin-bottom: 0;
          color: #333333;
          font-size: 18px;
          line-height: 1;
          font-weight: 500;
          text-decoration: none !important; }

.cart-wrap .table {
  border: 1px solid #D1D1D1; }
  @media only screen and (max-width: 991px) {
    .cart-wrap .table {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 16px; } }

.cart-wrap .cart-progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0; }
  .cart-wrap .cart-progress > li {
    position: relative;
    text-align: center; }
    .cart-wrap .cart-progress > li::before {
      content: " ";
      position: absolute;
      display: block;
      height: 6px;
      width: 98px;
      background-color: #CCCCCC;
      border-radius: 13px;
      left: 100%;
      margin-left: -15px;
      top: 21px; }
      @media only screen and (max-width: 767px) {
        .cart-wrap .cart-progress > li::before {
          left: 150%;
          width: 30px; } }
      @media only screen and (max-width: 400px) {
        .cart-wrap .cart-progress > li::before {
          display: none; } }
      @media only screen and (min-width: 768px) and (max-width: 1112px) {
        .cart-wrap .cart-progress > li::before {
          width: 70px; } }
    .cart-wrap .cart-progress > li::after {
      content: " ";
      position: absolute;
      height: 6px;
      width: 0;
      background-color: #ED3237;
      border-radius: 13px;
      left: 100%;
      margin-left: -15px;
      top: 21px;
      -webkit-transition: width 0.5s;
      /* For Safari 3.1 to 6.0 */
      transition: width 0.5s; }
      @media only screen and (max-width: 767px) {
        .cart-wrap .cart-progress > li::after {
          left: 150%; } }
      @media only screen and (max-width: 400px) {
        .cart-wrap .cart-progress > li::after {
          display: none; } }
    .cart-wrap .cart-progress > li:last-child::before {
      display: none; }
    .cart-wrap .cart-progress > li:last-child::after {
      display: none; }
    .cart-wrap .cart-progress > li .icon {
      margin: 0 auto;
      margin-bottom: 16px;
      border: 4px solid #CCCCCC;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center; }
      .cart-wrap .cart-progress > li .icon svg {
        fill: #ED3237;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px; }
    .cart-wrap .cart-progress > li p {
      max-width: 140px;
      text-align: center;
      margin-bottom: 0;
      font-size: 18px; }
      @media only screen and (max-width: 420px) {
        .cart-wrap .cart-progress > li p {
          display: none; } }
      .cart-wrap .cart-progress > li p:nth-child(2) {
        font-size: 18px;
        font-weight: 600; }
      @media only screen and (max-width: 767px) {
        .cart-wrap .cart-progress > li p:nth-child(3) {
          display: none; } }
    .cart-wrap .cart-progress > li:nth-child(1) svg {
      fill: #ED3237; }
  .cart-wrap .cart-progress .active::after {
    width: 49px; }
    @media only screen and (min-width: 768px) and (max-width: 1112px) {
      .cart-wrap .cart-progress .active::after {
        width: 35px; } }
    @media only screen and (max-width: 767px) {
      .cart-wrap .cart-progress .active::after {
        width: 15px; } }
  .cart-wrap .cart-progress .active .icon {
    background-color: #ED3237;
    border: 0; }
  .cart-wrap .cart-progress .active:nth-child(1) svg {
    stroke: #FFFFFF; }
  .cart-wrap .cart-progress .active:nth-child(4) svg {
    fill: #FFFFFF;
    stroke: unset; }
  .cart-wrap .cart-progress .done::after {
    width: 98px;
    display: block; }
    @media only screen and (min-width: 768px) and (max-width: 1112px) {
      .cart-wrap .cart-progress .done::after {
        width: 70px; } }
    @media only screen and (max-width: 767px) {
      .cart-wrap .cart-progress .done::after {
        width: 30px; } }
  .cart-wrap .cart-progress .done .icon {
    background-color: #ED3237;
    border: 0; }
  .cart-wrap .cart-progress .done:nth-child(1) svg {
    stroke: #FFFFFF; }
  .cart-wrap .cart-progress .done:nth-child(4) svg {
    fill: #FFFFFF;
    stroke: unset; }

@media only screen and (max-width: 991px) {
  .cart-wrap .button-group {
    padding: 0 !important; } }

.cart-wrap .back {
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 16px;
  height: 40px; }
  .cart-wrap .back::before {
    content: " ";
    display: block;
    border-bottom: 2px solid #ED3237;
    border-right: 2px solid #ED3237;
    transform: rotate(135deg);
    width: 8px;
    height: 8px;
    margin-right: 6px; }

.cart-wrap .checkout-button {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end; }
  .cart-wrap .checkout-button .button {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center; }
    .cart-wrap .checkout-button .button::after {
      content: " ";
      display: block;
      border-bottom: 2px solid #FFFFFF;
      border-right: 2px solid #FFFFFF;
      transform: rotate(-45deg);
      width: 8px;
      height: 8px;
      float: right;
      margin-left: 8px; }

.cart-wrap .checkout-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px; }
  .cart-wrap .checkout-buttons span {
    padding: 0 16px; }
  .cart-wrap .checkout-buttons .button {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center; }
    .cart-wrap .checkout-buttons .button::after {
      content: " ";
      display: block;
      border-bottom: 2px solid #FFFFFF;
      border-right: 2px solid #FFFFFF;
      transform: rotate(-45deg);
      width: 8px;
      height: 8px;
      float: right;
      margin-left: 8px; }

.cart-wrap .notes-input .input-wrap {
  margin-bottom: 0; }
  .cart-wrap .notes-input .input-wrap input[type=text] {
    height: 36px; }

.cart-wrap .coupon-input .input-wrap {
  margin-bottom: 0;
  margin-bottom: 32px; }
  .cart-wrap .coupon-input .input-wrap input[type=text] {
    height: 36px;
    width: 120px; }

.cart-wrap .cart-table {
  border: 1px solid #D1D1D1;
  border-bottom: 0;
  margin-bottom: 24px; }
  .cart-wrap .cart-table .header {
    background-color: #ED3237;
    height: 34px;
    display: flex;
    align-items: center; }
    .cart-wrap .cart-table .header > div {
      color: #FFFFFF;
      font-weight: 600; }
    .cart-wrap .cart-table .header .number {
      text-align: right; }
  .cart-wrap .cart-table .article-info {
    display: flex;
    align-items: center;
    font-size: 14px; }
    .cart-wrap .cart-table .article-info > div {
      padding: 12px 8px;
      border-right: 1px solid #D1D1D1;
      border-bottom: 1px solid #D1D1D1;
      height: 60px;
      display: flex;
      align-items: center; }
      .cart-wrap .cart-table .article-info > div:last-child {
        border-right: 0; }
    .cart-wrap .cart-table .article-info .select-field {
      width: 100%; }
      @media only screen and (max-width: 991px) {
        .cart-wrap .cart-table .article-info .select-field {
          font-size: 10px; } }
    @media only screen and (max-width: 991px) {
      .cart-wrap .cart-table .article-info {
        font-size: 12px; } }
    .cart-wrap .cart-table .article-info input[type=text] {
      height: 36px;
      width: 50px;
      border-radius: 6px;
      border: 1px solid #D1D1D1;
      padding: 0 16px;
      margin-right: 8px; }
      @media only screen and (max-width: 991px) {
        .cart-wrap .cart-table .article-info input[type=text] {
          width: 30px;
          padding: 0 8px; } }
    .cart-wrap .cart-table .article-info .number {
      justify-content: flex-end;
      padding-right: 12px; }
    .cart-wrap .cart-table .article-info .delete {
      justify-content: center; }
    .cart-wrap .cart-table .article-info label {
      margin-bottom: 0; }
      @media only screen and (max-width: 991px) {
        .cart-wrap .cart-table .article-info label {
          display: none; } }
    .cart-wrap .cart-table .article-info img {
      width: 44px;
      height: 33px;
      margin-right: 12px; }
  .cart-wrap .cart-table .payment-info {
    display: flex;
    align-items: center;
    font-size: 14px; }
    .cart-wrap .cart-table .payment-info > div {
      padding: 12px 8px;
      border-right: 1px solid #D1D1D1;
      border-bottom: 1px solid #D1D1D1;
      height: 500px; }
      .cart-wrap .cart-table .payment-info > div:last-child {
        border-right: 0; }
    .cart-wrap .cart-table .payment-info .select-field {
      width: 100%; }
      @media only screen and (max-width: 991px) {
        .cart-wrap .cart-table .payment-info .select-field {
          font-size: 10px; } }
    @media only screen and (max-width: 991px) {
      .cart-wrap .cart-table .payment-info {
        font-size: 12px; } }
    .cart-wrap .cart-table .payment-info .number {
      display: flex;
      justify-content: flex-end;
      padding-right: 12px; }
    .cart-wrap .cart-table .payment-info .delete {
      display: flex;
      justify-content: center; }
    .cart-wrap .cart-table .payment-info label {
      margin-bottom: 0; }
    .cart-wrap .cart-table .payment-info img {
      width: 44px;
      height: 33px;
      margin-right: 12px; }
